<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="设备编号" prop="deviceId">
        <a-input v-model="form.deviceId" placeholder="请输入设备编号" />
      </a-form-model-item>
      <a-form-model-item label="工号" prop="jobNumber">
        <a-input v-model="form.jobNumber" placeholder="请输入工号" />
      </a-form-model-item>
      <a-form-model-item label="身份证号码" prop="identityCard">
        <a-input v-model="form.identityCard" placeholder="请输入身份证号码" />
      </a-form-model-item>
      <a-form-model-item label="姓名" prop="name">
        <a-input v-model="form.name" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item label="汇总日期" prop="reportDate">
        <a-date-picker style="width: 100%" v-model="form.reportDate" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="上班打卡时间点" prop="toWorktTime">
        <a-date-picker style="width: 100%" v-model="form.toWorktTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="下班打卡时间点" prop="offWorktTime">
        <a-date-picker style="width: 100%" v-model="form.offWorktTime" value-format="YYYY-MM-DD HH:mm:ss" format="YYYY-MM-DD HH:mm:ss" allow-clear />
      </a-form-model-item>
      <a-form-model-item label="时长(秒)" prop="timeSum">
        <a-input v-model="form.timeSum" placeholder="请输入时长(秒)" />
      </a-form-model-item>
      <a-form-model-item label="里程(米)" prop="odoSum">
        <a-input v-model="form.odoSum" placeholder="请输入里程(米)" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm"> 保存 </a-button>
          <a-button type="dashed" @click="cancel"> 取消 </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getEmployeeDailyReport, addEmployeeDailyReport, updateEmployeeDailyReport } from '@/api/jt808/employeeDailyReport'

export default {
  name: 'CreateForm',
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        deviceId: null,
        jobNumber: null,
        identityCard: null,
        name: null,
        reportDate: null,
        toWorktTime: null,
        offWorktTime: null,
        timeSum: null,
        odoSum: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        deviceId: [{ required: true, message: '设备编号不能为空', trigger: 'blur' }],
        jobNumber: [{ required: true, message: '工号不能为空', trigger: 'blur' }],
        name: [{ required: true, message: '姓名不能为空', trigger: 'blur' }],
        reportDate: [{ required: true, message: '汇总日期不能为空', trigger: 'change' }]
      }
    }
  },
  filters: {},
  created() {},
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    onClose() {
      this.open = false
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        deviceId: null,
        jobNumber: null,
        identityCard: null,
        name: null,
        reportDate: null,
        toWorktTime: null,
        offWorktTime: null,
        timeSum: null,
        odoSum: null
      }
    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getEmployeeDailyReport(id).then((response) => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateEmployeeDailyReport(this.form).then((response) => {
              this.$message.success('修改成功', 3)
              this.open = false
              this.$emit('ok')
            })
          } else {
            addEmployeeDailyReport(this.form).then((response) => {
              this.$message.success('新增成功', 3)
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
