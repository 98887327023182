import request from '@/utils/request'
const baseURL = '/jt-api'
// 查询人员工牌在线日报列表
export function listEmployeeDailyReport(query) {
  return request({
    url: '/iot/employeeDailyReport/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询人员工牌在线日报详细
export function getEmployeeDailyReport(id) {
  return request({
    url: '/iot/employeeDailyReport/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增人员工牌在线日报
export function addEmployeeDailyReport(data) {
  return request({
    url: '/iot/employeeDailyReport',
    baseURL,
    method: 'post',
    data: data
  })
}

// 修改人员工牌在线日报
export function updateEmployeeDailyReport(data) {
  return request({
    url: '/iot/employeeDailyReport',
    baseURL,
    method: 'put',
    data: data
  })
}

// 删除人员工牌在线日报
export function delEmployeeDailyReport(id) {
  return request({
    url: '/iot/employeeDailyReport/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出人员工牌在线日报
export function exportEmployeeDailyReport(query) {
  return request({
    url: '/iot/employeeDailyReport/export',
    baseURL,
    responseType: 'blob',
    method: 'get',
    params: query
  })
}

// 执行日统计
export function runEmployeeDailyReport(query) {
  return request({
    url: '/iot/employeeDailyReport/runReport',
    method: 'get',
    baseURL,
    params: query
  })
}
